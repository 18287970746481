import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { jsonToFormData, validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';

interface Project {
  id: number;
  sbu_name: string;
  gst_no: string;
  state: {
    name: string;
  };
}

interface State {
  id: number;
  stage_name: any;
  name: any;
  count: number; // Assuming you want to count occurrences
}
interface Field {
  state_id: string;
  sbu_name: string;
  gst_no: string;
}

type EditAction = () => void;

const StateViewList = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');
  const [gstId, setGstId] = useState('');
  const [stateList, setStateList] = useState<State[]>([]);
  const [stateGst, setStateGst] = useState([]);
  const [role, setRole] = useState(0);

  const [fields, setFields] = useState<Field>({
    state_id: '',
    sbu_name: '',
    gst_no: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleShow = (data: any) => {
    setShow(true);
    setGstId(data.id);
    setFields({
      state_id: data.state_id,
      sbu_name: data.sbu_name,
      gst_no: data.gst_no
    });
  };
  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setGstId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`gstlist/${gstId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetchGst();
      setShowAlert(false);
      setGstId('');
    }
  };

  const handleClose = () => {
    setShow(false);
    setGstId('');
    setFields({
      state_id: '',
      sbu_name: '',
      gst_no: ''
    });
  };

  const handleSave = async () => {
    const data = jsonToFormData(fields);
    let response: any = {};
    if (gstId) {
      response = await addService(`gstlist/${gstId}`, data);
    } else {
      response = await addService('gstlist', data);
    }
    if (response?.status === 200) {
      fetchGst();
      handleClose();
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };

  const fetchGst = async () => {
    const response = await getService('gstlist');
    if (response.status === 200) {
      setStateGst(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  useEffect(() => {
    fetchGst();
    fetchState();
  }, []);

  const fetchState = async () => {
    const response = await getService('state-by-country/101');
    if (response.status === 200) {
      setStateList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'state.name',
      header: 'State Name',
      cell: ({ row: { original } }) => {
        const { name } = original.state;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{name}</p>
          </Link>
        );
      },
      meta: {
        headerProps: { style: { width: '50%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'sbu_name',
      header: 'SBU Name',
      meta: {
        headerProps: { style: { width: '20%' } }
        // cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'gst_no',
      header: 'GST No',
      meta: {
        headerProps: { style: { width: '20%' } }
        // cellProps: { className: 'text-body' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ActionDropdownItems
                  editAction={() => handleShow(original)}
                  removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: stateGst,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'State GST'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h3 className="mb-5 text-uppercase">State gst LIST</h3>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search..."
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {role === 1 && (
                  <Button
                    onClick={() => setShow(true)}
                    variant="primary"
                    className="px-5"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add GST State
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{gstId ? 'Edit' : 'Create'} GST State</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="d-grid gap-1">
                <FloatingLabel controlId="floatingSelectPrivacy" label="State">
                  <Form.Select
                    name="state_id"
                    value={fields['state_id']}
                    onChange={handleSelect}
                  >
                    <option>Select State</option>
                    {stateList.length > 0 &&
                      stateList.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
                <FloatingLabel controlId="floatingInputGrid" label="SBU name">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="sbu_name"
                    value={fields['sbu_name']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingInputGrid" label="GST NO.">
                  <Form.Control
                    type="text"
                    placeholder="ENTER GST"
                    name="gst_no"
                    value={fields['gst_no']}
                    onChange={handleChange}
                    maxLength={15}
                    style={{ textTransform: 'uppercase' }}
                  />
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default StateViewList;
