import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row, Table } from 'react-bootstrap';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { addService, getService } from 'services/services';
import {
  dateFormat,
  formatDateForAPI,
  validationErrorFromAPI
} from 'helpers/utils';
import DatePicker from 'components/base/DatePicker';
import { toast } from 'react-toastify';

interface Props {
  siteId: any;
}

// Define types for better TypeScript support
interface Staff {
  staff: {
    first_name: string;
    last_name: string;
  };
  date: string;
  inTime: string;
  outTime: string;
}

// eslint-disable-next-line react/prop-types
const AttendanceListView: React.FC<Props> = ({ siteId }) => {
  const [role, setRole] = useState(0);
  const [data, setData] = useState<any>([]);
  const [attDate, setAttDate] = useState<any>(new Date());
  const [value, setValue] = useState(0);
  const [statusId, setStatusId] = useState(0);
  const [apiJson, setApiJson] = useState([]);

  console.log('apiJson', apiJson);

  const handleSaveAll = async () => {
    const dataFilter = apiJson.filter((item: any) => item.attend_status == 1);
    console.log('dataFilter', dataFilter);
    const checkInTime = dataFilter.every((item: any) => item.in_time != null);
    console.log('checkInTime', checkInTime);
    if (checkInTime == true) {
      try {
        const response = await addService('staffsattend', {
          attendance: apiJson
        });

        if (response.status === 200) {
          toast.success('Data Saved!');
        } else {
          console.error('Unexpected error:', response); // Handle unexpected errors gracefully
        }
      } catch (error) {
        console.error('Error saving data:', error); // Handle any errors that might occur during the API call
      }
    } else {
      toast.error('Please fill Intime in present row');
    }
  };

  useEffect(() => {
    const saveData = data.map((item: any) => ({
      attend_status: item?.attend_status,
      staff_id: item.staff_attendance[0].staff_id,
      site_id: item.staff_attendance[0].site_id,
      staff_site_map_id: item.staff_attendance[0].staff_site_map_id,
      attendance_date: item.staff_attendance[0].attendance_date,
      in_time: item.staff_attendance[0].in_time || '',
      out_time: item.staff_attendance[0].out_time || ''
    }));
    setApiJson(saveData);
  }, [data]);

  const currentDate = new Date();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
    getStaff(attDate);
  }, []);

  const getStaff = async (date: any) => {
    const response = await getService(
      `staffattendance_by_id/${siteId}/${formatDateForAPI(date)}`
    );
    if (response.status === 200) {
      setData(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleDateChange = (index: number, date: any) => {
    const newData = [...data];
    const selectedDate = Array.isArray(date) ? date[0] : date;
    if (selectedDate) {
      newData[index] = {
        ...newData[index],
        date: selectedDate
      };
      setData(newData);
    }
  };

  const handleTimeChange = (
    index: number,
    field: 'in_time' | 'out_time',
    value: string
  ) => {
    const newData = [...data];
    newData[index] = {
      ...newData[index],
      staff_attendance: [
        {
          ...newData[index].staff_attendance[0],
          [field]: value
        }
      ]
    };
    setData(newData);
  };

  const handleSave = async (index: number) => {
    try {
      const indexData = data[index];
      if (
        !indexData ||
        !indexData.staff_attendance ||
        indexData.staff_attendance.length === 0
      ) {
        console.error('Invalid indexData:', indexData);
        alert('Data is incomplete');
        return;
      }

      // Extract the first attendance record
      const attendanceData = indexData.staff_attendance[0];

      console.log('indexData', indexData);
      console.log('PP', data);

      // Prepare request data
      const requestData = {
        site_id: siteId,
        staff_id: attendanceData.staff_id,
        staff_site_map_id: attendanceData.staff_site_map_id,
        attendance_date: formatDateForAPI(attendanceData.attendance_date),
        in_time: attendanceData.in_time || '',
        out_time: attendanceData.out_time || '',
        attend_status: indexData.attend_status
      };
      console.log('requestData', requestData);

      // Perform API call
      const response = await addService('staffattendance', requestData);

      // Check response status
      if (response.status === 200) {
        toast.success('Data Added');
      } else {
        console.error('Unexpected response status:', response.status);
        // alert('Failed to save data');
        toast.error('Failed to save data');
      }
    } catch (error) {
      console.error('Error saving data:', error);
      // Handle API error
      // validationErrorFromAPI(error);
      alert('Failed to save data');
    }
  };

  const handleDate = (date: any) => {
    if (date) {
      getStaff(date);
    }
  };

  const handleAttendance = (statusId: any) => {
    setValue(statusId);
    const updatedData = data.map((item: any) => ({
      ...item,
      attend_status: statusId
    }));
    setData(updatedData);
  };

  const handleAttendanceStatus = (index: number, statusId: any) => {
    const newData = [...data];
    if (statusId) {
      newData[index] = {
        ...newData[index],
        attend_status: statusId
      };
      setData(newData);
      console.log('kkk', newData);
    }
  };

  return (
    <div>
      <div className="mb-9">
        <Row className="align-items-center mb-5">
          <Col xs="auto">
            <h4>Attendance Sheet</h4>
          </Col>
          <Col xs="auto">
            <DatePicker
              value={attDate}
              options={{
                enableTime: false,
                dateFormat: 'Y-m-d',
                maxDate: currentDate
              }}
              onChange={date => handleDate(date)}
              render={(_, ref) => (
                <Form.Floating>
                  <Form.Control
                    type="text"
                    placeholder="Project title"
                    ref={ref}
                    id="startDate"
                  />
                  <label htmlFor="startDate" className="ps-6">
                    Date filter
                  </label>
                </Form.Floating>
              )}
            />
          </Col>
          <Col xs="auto">
            <FloatingLabel
              controlId="floatingSelectTask"
              label="Attendance Status"
            >
              <Form.Select
                name="Attendance"
                onChange={e => handleAttendance(e.target.value)}
              >
                <option value="0">Select Status</option>
                <option value="1">Present</option>
                <option value="2">Weekly off</option>
                <option value="3">Paid Leave</option>
                <option value="4">Absent</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
          {value != 0 && (
            <Col xs="auto">
              <Button
                variant="primary"
                className="py-2"
                onClick={handleSaveAll}
              >
                Save All
              </Button>
            </Col>
          )}
        </Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="text-center">Staff Name</th>
              <th className="text-center">Attendance Status</th>
              <th>Date</th>
              <th>In Time</th>
              <th>Out Time</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((staff: any, index: any) => (
                <tr key={index}>
                  <td className="text-center">{`${staff?.staff_detail?.staff?.first_name} ${staff?.staff_detail?.staff?.last_name}`}</td>
                  <td>
                    <Col xs="auto">
                      {/* <FloatingLabel
                        controlId="floatingSelectTask"
                        label="Attendance"
                      > */}
                      <FloatingLabel
                        controlId={`floatingSelectTask-${staff.id}`}
                        label={`Attendance`}
                      >
                        <Form.Select
                          name="Attendance"
                          value={
                            staff.attend_status ||
                            staff.staff_attendance[0]?.attend_status
                          }
                          onChange={e =>
                            handleAttendanceStatus(index, e.target.value)
                          }
                        >
                          <option value="0">Select Status</option>
                          <option value="1">Present</option>
                          <option value="2">Weekly off</option>
                          <option value="3">Paid Leave</option>
                          <option value="4">Absent</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                  </td>
                  <td>
                    <Col xs="auto">
                      <DatePicker
                        value={staff.staff_attendance[0]?.attendance_date}
                        options={{
                          enableTime: false,
                          dateFormat: 'Y-m-d'
                        }}
                        onChange={date => handleDateChange(index, date)}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                type="text"
                                placeholder="Project title"
                                ref={ref}
                                id="startDate"
                                disabled
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                  </td>
                  <td>
                    <input
                      type="time"
                      className="form-control"
                      value={staff.staff_attendance[0]?.in_time || ''}
                      onChange={e =>
                        handleTimeChange(index, 'in_time', e.target.value)
                      }
                      // disabled={staff.staff_attendance[0]?.in_time}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="time"
                      className="form-control"
                      value={staff.staff_attendance[0]?.out_time || ''}
                      onChange={e =>
                        handleTimeChange(index, 'out_time', e.target.value)
                      }
                      // disabled={staff.staff_attendance[0]?.out_time}
                    />
                  </td>
                  <td className="text-center">
                    <Button
                      variant="primary"
                      className="py-2"
                      onClick={() => handleSave(index)}
                      // disabled={
                      //   staff.staff_attendance[0]?.in_time &&
                      //   staff.staff_attendance[0]?.out_time
                      // }
                    >
                      Save
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default AttendanceListView;
