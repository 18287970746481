import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getService } from 'services/services';
import {
  dateFormat,
  formatDateForAPI,
  imageURL,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

interface contractorDetails {
  id: number;
  user_id: number;
  aadhar_no: number;
  first_name: string;
  last_name: string;
  mobile_no: string;
  email: string;
  license_no: string;
  address: string;
  profile_image: any;
  citydata: {
    name: string;
  };
  statedata: {
    name: string;
  };
  countrydata: {
    name: string;
  };
}

const ContractorProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [contractorData, setContractorData] = useState<contractorDetails>();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await getService(`contractor/${id}`);
    if (response.status === 200) {
      setContractorData(response.data[0]);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col md={12}>
          <h1>Manager Profile</h1>
          <div className="text-center">
            <img
              src={
                contractorData?.profile_image
                  ? `${imageURL}/public/${contractorData.profile_image}`
                  : 'https://static.vecteezy.com/system/resources/previews/024/183/502/original/male-avatar-portrait-of-a-young-man-with-a-beard-illustration-of-male-character-in-modern-color-style-vector.jpg' // Replace with your default image URL
              }
              alt={`${contractorData?.first_name} ${contractorData?.last_name}`}
              style={{ width: '200px', height: '200px' }}
              className="border rounded-circle"
            />
            <h3 className="mt-5">
              {contractorData?.first_name} {contractorData?.last_name}
            </h3>
            <h3 className="mt-5">
              {' '}
              <strong>Licence No. </strong>
              {contractorData?.license_no}
            </h3>
          </div>
          <div className="text-center">
            <p>
              <strong>Email:</strong> {contractorData?.email}
              <br />
              <strong>Aadhar No:</strong> {contractorData?.aadhar_no}
              <br />
              <strong>Mobile:</strong> {contractorData?.mobile_no}
              <br />
              <strong>Address:</strong> {contractorData?.address},{' '}
              {contractorData?.citydata?.name}, {contractorData?.statedata.name}
              , {contractorData?.countrydata?.name}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContractorProfile;
