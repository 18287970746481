const ReportList = () => {
  return (
    <div>
      <h1>Report List</h1>
      <h4>Dispatch Report/ Challan Report</h4>
      <h4>Dispatch Return Report</h4>
      <h4>Purchas Return</h4>
    </div>
  );
};
export default ReportList;
