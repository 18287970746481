import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import SiteMateReqViewTable from './SiteMateReqViewTable';
import SiteMachinaryReport from './SiteMachinaryReport';
import ChartLegend from 'components/common/ChartLegend';
import EcomTopCouponsChart from 'components/charts/e-charts/EcomTopCouponsChart';
import CompletedTaskChart from 'components/charts/e-charts/CompletedTaskChart';
import EcomProjectionVsActual from 'components/modules/e-commerce/dashboard/EcomProjectionVsActual';
import { getService } from 'services/services';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { EChartsOption } from 'echarts';

// Register ECharts components
echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer]);

const SitesReportViewTable = () => {
  const [activeSite, setActiveSite] = useState<number>(0);
  const [compSite, setCompSite] = useState<number>(0);
  const [retention, setRetention] = useState<number>(0);
  const [claim, setClaim] = useState<number>(0);
  const [received, setReceived] = useState<number>(0);

  const TotalSite = compSite + activeSite;

  useEffect(() => {
    SiteActive();
    SiteComplete();
    SiteRetention();
  }, []);

  const SiteComplete = async () => {
    const response = await getService('site');
    if (response.status === 200) {
      setActiveSite(response.data.length);
    }
  };

  const SiteActive = async () => {
    const response = await getService('site_completed');
    if (response.status === 200) {
      setCompSite(response.data.length);
    }
  };

  const SiteRetention = async () => {
    const response = await getService('retention');
    if (response.status === 200) {
      const claimed = response.data.filter(
        (item: any) => item.status === 1
      ).length;
      setClaim(claimed);
      const receive = response.data.filter(
        (item: any) => item.status === 2
      ).length;
      setReceived(receive);
      setRetention(response.data.length);
    }
  };

  const option: EChartsOption = {
    color: ['green', 'yellow'],
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'Site Count',
        type: 'pie',
        radius: ['40%', '70%'], // Inner and outer radius for the donut
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
          fontWeight: 'bold'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          // { value: TotalSite, name: 'Total Site' },
          { value: compSite, name: 'Complete Sites' },
          { value: activeSite, name: 'Active Sites' }
        ]
      }
    ]
  };
  const options: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'Staff',
        type: 'pie',
        radius: ['40%', '70%'], // Inner and outer radius for the donut
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
          fontWeight: 'bold'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          // { value: TotalSite, name: 'Total Retention' },
          { value: claim, name: 'Active' },
          { value: received, name: 'Inactive' }
          // { value: retention - (claim + received), name: 'Pending' }
        ]
      }
    ]
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={3} className="mt-3">
          <div className="border rounded" style={{ height: '400px' }}>
            <h4 className="text-center my-3"> Site Progress</h4>
            <div style={{ width: '100%', height: '400px' }}>
              <ReactECharts option={option} />
              <div className="d-flex justify-content-around">
                <p>Total: {TotalSite}</p>
                <p>Complete: {compSite}</p>
                <p>Active: {activeSite}</p>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={9} className="mt-3">
          <div
            className="px-4 py-3 border rounded"
            style={{ height: '400px', overflow: 'auto' }}
          >
            <SiteMateReqViewTable />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={8} className="mt-3">
          <div
            className="px-4 py-3 border rounded"
            style={{ height: '400px', overflow: 'auto' }}
          >
            <SiteMachinaryReport />
          </div>
        </Col>
        <Col xs={4} className="mt-3">
          <div className="border rounded py-2" style={{ height: '400px' }}>
            <h3 className="text-center text-uppercase">Staff</h3>
            <div style={{ width: '100%', height: '400px' }}>
              <ReactECharts option={options} />
              <div className="d-flex justify-content-around">
                <p className="text-center py-2">Active: {claim}</p>
                <p className="text-center py-2">Inactive: {received}</p>
                {/* <p className="text-center py-2">
                  Pending: {retention - (claim + received)}
                </p> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SitesReportViewTable;
