import {
  faPaperclip,
  faPlus,
  faRemove
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import ReactSelect from 'components/base/ReactSelect';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';
import { calculateGST, validationErrorFromAPI } from 'helpers/utils';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, deleteService, getService } from 'services/services';

interface Supplier {
  id: number;
  supplier_name: string;
  name: string;
}
interface StateGST {
  id: number;
  name: string;
}
interface Material {
  id: any;
  name: string;
  unitdata?: any;
}

interface SupplierField {
  id?: number | string;
  supplier_id: number | string;
  invoice_no?: any;
  material_id?: any;
  barcode?: any;
  batch_no?: any;
  stock?: any;
  purchase_rate?: any;
  gst?: any;
  gst_amt?: any;
  total_amount?: any;
}
interface Field {
  id?: number | string;
  material_id?: any;
  unit_id?: string;
  barcode?: any;
  batch_no?: any;
  maintan_date?: any;
  quantity?: any;
  rate?: any;
  gst?: any;
  gst_amt?: any;
  total_amount?: any;

  // quantity?: any;
  // rate_per_site?: any;
  // site_rate?: any;
  // tpgst?: any;
}

interface FormData {
  // supplier_id: string;
  // invoice_no: string;
  // invoice_date: string | Date;
  // note: string;
  // invoice_document: any;
  // sbu_id: string;
  company_id: string;
  name: string;
  mobile_number: string;
  email: string;
  q_date: string | Date;
}
const CreateQuotation = () => {
  const navigate = useNavigate();
  const [supplierList, setSupplierList] = useState<Supplier[]>([]);
  const [stateList, setStateList] = useState<StateGST[]>([]);
  const [materialList, setMaterialList] = useState<Material[]>([]);
  // const [image, setImage] = useState<File | null>(null);
  const [fields, setFields] = useState<Field[]>([
    {
      material_id: '',
      unit_id: '',
      // barcode: '',
      // batch_no: '',
      // maintan_date: '',
      quantity: '',
      rate: '',
      gst: '',
      gst_amt: '',
      total_amount: ''
    }
  ]);

  const [formData, setFormData] = useState<FormData>({
    company_id: '',
    name: '',
    mobile_number: '',
    email: '',
    q_date: ''
  });

  console.log('formData', formData);
  console.log('fields', fields);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files && event.target.files[0];
  //   if (file) {
  //     setImage(file);
  //     setFormData(prevFields => ({
  //       ...prevFields,
  //       invoice_document: file
  //     }));
  //   }
  // };

  const handleAddField = () => {
    const newObj: Field = {
      // id: '',
      material_id: '',
      unit_id: '',
      barcode: '',
      batch_no: '',
      maintan_date: '',
      quantity: '',
      rate: '',
      gst: '',
      gst_amt: '',
      total_amount: ''
    };
    // Add newObj to originalArray
    // const originalfield: Field[] = fields.concat(newObj);
    const originalfield: Field[] = [newObj, ...fields];
    setFields(originalfield);
  };

  const handleRemoveField = async (indexToRemove: number, id?: any) => {
    // Filter out the object to remove based on its index
    const updatedFields: Field[] = fields.filter(
      (_, index) => index !== indexToRemove
    );
    // Update the state with the filtered array
    setFields(updatedFields);
    // if (id) {
    //   await deleteService(`site_inventory/${id}`);
    // }
  };

  const handleSelect = (
    event: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const { value } = event.target;
    const foundMaterial = materialList.find(material => material.id == value);
    console.log('foundMaterial', foundMaterial);
    // Make a shallow copy of the array of fields
    const fieldset = [...fields];
    // // Make a shallow copy of the specific field object within the array
    const updatedField = { ...fieldset[index] };
    // // Update the specific property within the copied field object
    updatedField['material_id'] = value;
    // updatedField['unit_id'] = toString(foundMaterial?.unitdata?.id);
    updatedField['unit_id'] = foundMaterial?.unitdata?.id?.toString();
    // // // Update the array with the updated field object at the specified index
    fieldset[index] = updatedField;
    // // // Set the state with the updated array
    setFields(fieldset);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const fieldset = [...fields];
    const updatedField = { ...fieldset[index] };
    const fieldName = name as keyof Field;
    updatedField[fieldName] = value;

    if (
      fieldName === 'quantity' ||
      fieldName === 'rate' ||
      fieldName === 'gst'
    ) {
      const stock = parseFloat(updatedField['quantity']) || 0;
      const purchaseRate = parseFloat(updatedField['rate']) || 0;
      const gst = parseFloat(updatedField['gst']) || 0;

      const site_rate = stock * purchaseRate;
      const gstAmount = (site_rate * gst) / 100;
      const totalAmount = site_rate + gstAmount;

      // updatedField['site_rate'] = site_rate.toFixed(2);
      updatedField['gst_amt'] = gstAmount.toFixed(2);
      updatedField['total_amount'] = totalAmount.toFixed(2);
    }

    fieldset[index] = updatedField;
    setFields(fieldset);
  };

  const handleDateChanges = (date: any) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding leading zero if needed
    const day = ('0' + date.getDate()).slice(-2); // Adding leading zero if needed

    const formattedDate = `${year}-${month}-${day}`;
    setFormData(prevFormData => ({
      ...prevFormData,
      q_date: formattedDate
    }));
  };

  const handleDateChange = (date: any, index: number) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding leading zero if needed
    const day = ('0' + date.getDate()).slice(-2); // Adding leading zero if needed
    const formattedDate = `${year}-${month}-${day}`;
    const fieldset = [...fields];
    const updatedField = { ...fieldset[index] };
    updatedField['maintan_date'] = formattedDate;
    fieldset[index] = updatedField;
    setFields(fieldset);
  };

  useEffect(() => {
    fetchSupplier();
    fetchMaterial();
    fetchState();
  }, []);

  const fetchSupplier = async () => {
    const response = await getService('company');
    if (response.status === 200) {
      setSupplierList(response.data);
    }
  };
  const fetchMaterial = async () => {
    const response = await getService('material');
    if (response.status === 200) {
      setMaterialList(response.data);
    }
  };
  const fetchState = async () => {
    const response = await getService('gstlist');
    if (response.status === 200) {
      setStateList(response.data);
    }
  };

  const isObjectComplete = (obj: any) => {
    return Object.values(obj).every((value: any) => value.trim() !== '');
  };

  const handleSubmit = async () => {
    // console.log('fields', fields);
    // const filteredFields = fields.map(field => {
    //   const { unit, ...rest } = field;
    //   return rest;
    // });
    const filteredFields = fields.filter(isObjectComplete);
    if (filteredFields.length === 0) {
      toast.error('Please fill all the fields');
    } else {
      const combinedData = {
        ...formData,
        q_material: JSON.stringify(filteredFields)
      };

      console.log('combinedData', combinedData);
      const response = await addService('quotation', combinedData);
      if (response?.status === 200) {
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        navigate('/quotationListView');
      } else if (response?.status === 500) {
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    }
  };

  return (
    <div>
      <h2 className="mb-4">Create Quotation</h2>
      <Row>
        <Col xs={12} xl={12}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={2}>
              <FloatingLabel controlId="floatingSelectTask" label="Company">
                <Form.Select
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleInputChange}
                >
                  <option>Select Company</option>
                  {supplierList.length > 0 &&
                    supplierList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={2}>
              <FloatingLabel controlId="floatingInputGrid" label="Person Name">
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={2}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Mobile Number"
              >
                <Form.Control
                  name="mobile_number"
                  type="text"
                  placeholder="Mobile Number"
                  value={formData.mobile_number}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={2}>
              <FloatingLabel controlId="floatingInputGrid" label="Email">
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={2}>
              <DatePicker
                value={formData['q_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  handleDateChanges(date);
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Quotation Date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <div>
              <Col xs={12} xl={12}>
                {fields.map((field, index) => {
                  return (
                    <Row as="form" className="g-3 mb-6" key={index}>
                      <Col sm={4} md={2}>
                        <FloatingLabel
                          controlId="floatingSelectPrivacy"
                          label="Material"
                        >
                          <Form.Select
                            value={fields[index]['material_id']}
                            onChange={event => handleSelect(event, index)}
                          >
                            <option>Select Material</option>
                            {materialList.length > 0 &&
                              materialList.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Unit"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="unit_id"
                            value={fields[index]['unit_id']}
                          />
                        </FloatingLabel>
                      </Col>
                      {/* <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Barcode"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="barcode"
                            value={fields[index]['barcode']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Batch No"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="batch_no"
                            value={fields[index]['batch_no']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <DatePicker
                          value={fields[index]['maintan_date']}
                          options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                          onChange={([date]) => {
                            handleDateChange(date, index);
                          }}
                          render={(_, ref) => {
                            return (
                              <Form.Floating>
                                <Form.Control
                                  type="text"
                                  placeholder="Project title"
                                  ref={ref}
                                  id="startDate"
                                />
                                <label htmlFor="startDate" className="ps-6">
                                  EXP. date
                                </label>
                              </Form.Floating>
                            );
                          }}
                        />
                      </Col> */}
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Quantity"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="quantity"
                            value={fields[index]['quantity']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Rate"
                        >
                          <Form.Control
                            type="number"
                            placeholder="number"
                            name="rate"
                            value={fields[index]['rate']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="GST %"
                        >
                          <Form.Control
                            type="text"
                            placeholder="GST"
                            name="gst"
                            value={fields[index]['gst']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="GST Amount"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="gst_amt"
                            value={fields[index]['gst_amt']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Total Amount"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="total_amount"
                            value={fields[index]['total_amount']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={2}>
                        <Button
                          variant="primary"
                          className="px-5"
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          onClick={() =>
                            index === 0
                              ? handleAddField()
                              : handleRemoveField(index, field.id)
                          }
                        >
                          {index === 0 ? 'Enter' : 'Close'}
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </div>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/quotationListView">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSubmit}
                >
                  Generate Quotation
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateQuotation;
