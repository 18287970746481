import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  Col,
  Form,
  Modal,
  Row,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getService } from 'services/services';
import { validationErrorFromAPI } from 'helpers/utils';
import { FloatingLabel } from 'react-bootstrap';
import JsonToCsvButton from 'components/JsonToCsvButton';

interface StaffList {
  staff_detail: {
    id: number;
    first_name: string;
    last_name: string;
  };
  total_time: string;
  over_time: string;
  total_day_amount: number;
  day_amount: number;
  ot_time_amt: number;
}

interface StaffList {
  id: number;
  staff_id: number;
  site_id: number;
  staff_site_map_id: number;
  attend_status: number;
  attendance_date: string;
  in_time: string;
  out_time: string;
  total_time: string;
  over_time: string;
  ot_time_amt: number;
  day_amount: number;
  total_day_amount: number;
  created_at: string;
  staff: {
    id: 1;
    first_name: string;
    last_name: string;
    email: string;
    mobile_no: string;
    aadhar_no: string;
  };
  site: {
    site_name: string;
  };
}

const Attendance = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [attendanceList, setAttendanceList] = useState([]);
  const [role, setRole] = useState(0);
  const [year, setYear] = useState<any>(null);
  const [month, setMonth] = useState<any>(null);

  console.log('attendanceList', attendanceList);

  useEffect(() => {
    fetch();
    getCurrentMonthNumber();
  }, []);

  const getCurrentMonthNumber = () => {
    const today = new Date();
    setMonth(today.getMonth() + 1);
    setYear(today.getFullYear());
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const extractTime = (timestamp: string) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${hours}:${minutes}:${seconds}`;
  };

  const fetch = async () => {
    const response = await getService(
      //   `single_staff_attendance_month_report/${id}/${site_id}/${month}/${year}`
      `single_staff_attendance_month_report/${id}/${0}/${0}/${0}`
    );
    if (response.status === 200) {
      setAttendanceList(response.data.staff_attendance);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleMonthChange = async (monthId: any) => {
    if (monthId) {
      setMonth(monthId);
      const response = await getService(
        `single_staff_attendance_month_report/${id}/${0}/${monthId}/${
          year ? year : 0
        }`
      );
      if (response.status === 200) {
        setAttendanceList(response.data.staff_attendance);
      }
    } else {
      `single_staff_attendance_month_report/${id}/${0}/${month ? month : 0}/${
        year ? year : 0
      }`;
    }
  };

  const handleYearChange = async (yearId: any) => {
    if (yearId) {
      setYear(yearId);
      const response = await getService(
        `single_staff_attendance_month_report/${id}/${0}/${
          month ? month : 0
        }/${yearId}`
      );
      if (response.status === 200) {
        setAttendanceList(response.data.staff_attendance);
      }
    } else {
      `single_staff_attendance_month_report/${id}/${0}/${month ? month : 0}/${
        year ? year : 0
      }`;
    }
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const getMonth = (numericMonth: any): string => {
    if (numericMonth === null || numericMonth < 1 || numericMonth > 12) {
      return 'Invalid Month';
    }
    return monthList[numericMonth - 1];
  };

  const filteredData = attendanceList.map((item: any) => ({
    Date: item.attendance_date,
    Staff_Name: `${item.staff.first_name} ${item.staff.last_name}`,
    mobile_no: item.staff.mobile_no,
    email: item.staff.email,
    aadhar_no: item.staff.aadhar_no,
    address: item.staff.address,
    medical_leave: item.staff.medical_leave,
    paid_leave: item.staff.paid_leave,
    account_no: item.staff.account_no,
    bank_name: item.staff.bank_name,
    ifsc_code: item.staff.ifsc_code,
    joining_date: item.staff.joining_date,
    Site_Name: item.site.address,
    Entry_Time: item.created_at,
    In_Time: item.in_time,
    Out_Time: item.out_time,
    Working_Time: item.total_time,
    Overtime_Hours: item.over_time,
    monthly_salary: item.staff.monthly_salary,
    overtime_rate: item.staff.overtime_rate
  }));

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<StaffList>[] = [
    {
      accessorKey: 'id',
      header: 'Date',
      cell: ({ row: { original } }) => {
        const { attendance_date } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {attendance_date}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'first_name',
      header: 'Staff Name',
      cell: ({ row: { original } }) => {
        const { first_name, last_name } = original.staff;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {`${first_name} ${last_name}`}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'total_time',
      header: 'Site Name',
      cell: ({ row: { original } }) => {
        const { site_name } = original.site;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {site_name}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'over_time',
      header: 'Entry Time',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {extractTime(created_at)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: 'In Time',
      cell: ({ row: { original } }) => {
        const { in_time } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{in_time}</p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: 'Out Time',
      cell: ({ row: { original } }) => {
        const { out_time } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{out_time}</p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'total_time',
      header: 'Working Hours',
      cell: ({ row: { original } }) => {
        const { total_time } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {Math.round(Number(total_time) / 60)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: 'Overtime Hours',
      cell: ({ row: { original } }) => {
        const { over_time } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {Math.round(Number(over_time) / 60)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: attendanceList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-5">Attendance Details</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Month Filter"
                >
                  <Form.Select
                    name="Month Filter"
                    value={month}
                    onChange={e => handleMonthChange(e.target.value)}
                  >
                    <option value="0">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Year Filter"
                >
                  <Form.Select
                    name="Year Filter"
                    value={year}
                    onChange={e => handleYearChange(e.target.value)}
                  >
                    <option value="0">Select Month</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2027">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {/* <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button> */}
                <JsonToCsvButton
                  data={filteredData}
                  filename="Attendance Details Report.csv"
                />
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default Attendance;
